<template>
  <!-- 笔记详情页 -->
  <div class="note-details">
    <div class="note-details-box">
      <div class="note-details-item">
        <div class="item-top">
          <span class=" hand" @click="goUpper"> &lt; &lt; 返回一上级</span>
        </div>
        <div class="document_info_box">
          <h3 class="doc_title">{{info.docTitle}}</h3>
          <p class="info_box">
            <span>发表时间: {{$initTime(info.docPublishTime)}}</span>
            <span> IF: {{info.docIf || '暂无'}}</span>
            <span> 来源期刊: {{info.docSourceJournal || '暂无'}}</span>
            <span> 被引用: {{info.citedBy}}</span>
            <span class="goOriginalText" @click="goOriginal">查看原文</span>
          </p>
          <p>DOI: {{info.docDoi}}
            <img v-clipboard:copy="info.docDoi" v-clipboard:success="onCopy"
                 v-clipboard:error="onError" class="copy-img  hand"
                 src="../../assets/img/docuInfo/fz.png">
          </p>
          <p>PMID: {{info.pmid}}
            <img v-clipboard:copy="info.pmid" v-clipboard:success="onCopy"
                 v-clipboard:error="onError" class="copy-img  hand"
                 src="../../assets/img/docuInfo/fz.png">
          </p>
          <p>作者: {{info.docAuthor}}</p>
        </div>
        <div class="item-box">
          <div class="item-left-row" v-for="note in noteList" :key="note.id">
            <div class="row-top">
              <span class="note-type">{{note.type?'全文':'段落'}}笔记</span>
              <span class="note-time">{{$initTime(note.createdTime,'yyyy-MM-DD HH:mm')}}</span>
              <!-- <img  class="" src="@/assets/img/index/ljt.png"> -->
              <i @click="deleteOnte(note.id)" class="el-icon-delete top-icon"></i>
            </div>
            <div class="note-title">
              <!-- icon-biji icon-duanla-->
              <i class="iconfont icon-a-bijiiconqiepian "></i>
              <span class="text-title">笔记</span>
            </div>
            <div class="row-text">{{note.noteText}}</div>
            <div class="note-title">
              <i class="iconfont icon-a-bianzu51"></i>
              <span class="text-title">{{!note.type?'段落':'全文'}}</span>
            </div>
            <div v-if="!note.type" class="row-duanluo">{{note.originalText}}</div>
            <!-- <div v-else class="dl-kong">全文</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getDocumentById } from '@/api/article.js'
import { blankPagePar } from '@/utils/index.js'
import { getNotesByDocumentId, deleteNote } from '@/api/note.js'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      id: '', // 文献id
      info: {}, // 文献信息
      noteList: [] // 笔记列表
    }
  },
  methods: {
    // 删除笔记
    async deleteNote (noteId) {
      try {
        await deleteNote({ noteId })
        this.$message.success('删除笔记成功成功')
        this.getNotes(() => {
          if (this.noteList.length === 0) {
            this.$router.push('/userInfo/myNote')
          }
        })
      } catch (error) {
        this.$message.error('删除笔记失败')
      }
    },
    // 获取当前文献的笔记列表
    async getNotes (fn) {
      try {
        const res = await getNotesByDocumentId({ documentId: this.id })
        this.noteList = res.data
        fn && fn()
      } catch (error) {
        this.$message.error('获取笔记列表失败')
      }
    },
    // 复制成功
    onCopy () {
      this.$message.success('复制成功')
    },
    // 复制失败
    onError () {
      this.$message.error('复制失败')
    },
    // 删除笔记
    deleteOnte (noteId) {
      this.$confirm('确认删除这条笔记吗?', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
        type: 'warning'
      }).then(() => {
        this.deleteNote(noteId)
      }).catch(() => {
      })
    },
    // 获取原文url地址跳转原文页
    async goOriginal () {
      try {
        blankPagePar('pdfDetails', { id: this.id })
      } catch (error) {
        this.$message.error('获取原文信息失败')
      }
    },
    // 获取文献详情
    async getDocInfo () {
      try {
        const res = await getDocumentById({ documentId: this.id })
        this.info = res.data
      } catch (error) {
        this.$message.error('获取文献信息失败')
      }
    },
    // 返回上一级
    goUpper () {
      this.$router.go(-1)
    }
  },
  computed: {
    ...mapGetters(['userId'])
  },
  created () {
    this.id = this.$route.query.id
    this.getDocInfo()
    this.getNotes()
  }
}
</script>
<style lang="less" scoped>
.note-details {
  .note-details-box {
    min-height: 70vh;
    background-color: #f5f7fb;
  }
  .note-details-item {
    width: 1200px;
    min-height: 70vh;
    margin: 0 auto;
    // 上面盒子
    .item-top {
      width: 100%;
      padding: 20px 0;
      font-size: 14px;
      color: #4b639f;
    }
    // 文献信息盒子
    .document_info_box {
      width: 100%;
      border-radius: 2px;
      background-color: #fff;
      padding: 20px;
      margin-bottom: 20px;
      .doc_title {
        font-size: 20px;
        font-weight: 600;
      }
      p {
        font-size: 14px;
        color: #666;
        margin-top: 10px;
        span {
          margin-right: 8px;
          &::after {
            content: '/';
            margin-left: 8px;
            color: #000;
          }
          &:last-child {
            margin-right: 0;
            &::after {
              content: '';
              margin-left: 0;
            }
          }
        }
        .copy-img {
          width: 16px;
          height: 16px;
        }
      }
      .info_box {
        position: relative;
        .goOriginalText {
          position: absolute;
          top: 0;
          right: 0;
          width: 104px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          background-color: #4b639f;
          color: #fff;
          cursor: pointer;
        }
      }
    }
    // 下面盒子
    .item-box {
      // display: flex;
      width: 100%;
      min-height: 62vh;
      padding-bottom: 20px;
      .item-left-row {
        margin-bottom: 20px;
        background-color: #fff;
        border-radius: 2px;
        padding-bottom: 20px;
        overflow: hidden;
        &:hover {
          box-shadow: 6px 6px 7px 0 rgba(204, 204, 204, 0.5);
          transition: all 0.3s;
        }
        &:last-child {
          margin-bottom: 0;
        }
        .row-top {
          position: relative;
          width: 100%;
          height: 40px;
          line-height: 40px;
          padding-left: 20px;
          margin-bottom: 20px;
          background-color: #d7dce9;
          .note-type {
            font-size: 14px;
            font-weight: 600;
            margin-right: 20px;
          }
          .note-time {
            font-size: 14px;
            color: #666;
          }
          .top-icon {
            font-size: 18px;
            position: absolute;
            right: 20px;
            top: 0;
            height: 40px;
            line-height: 40px;
            cursor: pointer;
            transition: top 0.2s;
            &:hover {
              top: -2px;
            }
          }
        }
        .note-title {
          padding-left: 20px;
          font-size: 16px;
          .iconfont {
            font-size: 15px;
            margin-right: 10px;
            color: #4b639f;
          }
        }
        .row-text {
          margin: 5px 0 20px;
          font-size: 14px;
          padding: 0 20px;
        }
        .row-duanluo {
          font-size: 14px;
          color: #888;
          margin-top: 5px;
          padding: 0 20px;
        }
        .dl-kong {
          font-size: 14px;
          text-align: center;
          height: 50px;
          line-height: 50px;
        }
      }
      // .item-right {
      //   width: 330px;
      //   padding-left: 20px;
      //   .yuanwen-btn {
      //     position: relative;
      //     width: 210px;
      //     height: 40px;
      //     line-height: 40px;
      //     text-align: center;
      //     border-radius: 8px;
      //     margin: 0 35px 50px 0;
      //     font-size: 16px;
      //     font-weight: bold;
      //     color: #fff;
      //     background: #4b639f;
      //     border: 1px solid #4b639f;
      //     &:hover {
      //       top: -2px;
      //       transition: all 0.7s;
      //     }
      //   }
      //   ul {
      //     font-size: 18px;
      //     color: #999999;
      //     line-height: 25px;
      //     padding-bottom: 20px;
      //     & :first-child {
      //       margin-bottom: 20px;
      //     }
      //     li {
      //       margin-bottom: 10px;
      //       h3 {
      //         font-size: 22px;
      //         font-weight: 600;
      //         color: #000;
      //       }
      //       span {
      //         margin-left: 10px;
      //       }
      //       .copy-img {
      //         width: 20px;
      //         height: 20px;
      //         margin-left: 10px;
      //       }
      //     }
      //   }
      // }
    }
  }
}
</style>
